<template>
  <div class="lists">
    <v-row class="one-list" v-for="(item,index) in data" :key="index">
      <v-row class="one-list-container" tabindex="0">
        <v-col cols="3" md="3">
          <newImg :fileUrl="item.image||item.roomImage" style="width:100%;height: 100%;object-fit: contain"></newImg>
        <!-- <img :src="item.image||item.roomImage" style="width:100%;height: 100%;object-fit: contain" alt=""> -->
        </v-col>
        <v-col cols="6" md="6">
          <v-row class="xtitle">
            {{ item.name || item.resourceName }}
          </v-row>
          <v-row>
            <span class="deskListTitle">Buildings&nbsp;:&nbsp;</span>
            <span>{{ item.buildingName }} , </span>
            <span>&nbsp;Floor&nbsp;:&nbsp;</span>
            <span>{{ item.floorName }}</span>
          </v-row>
          <v-row>
            <span class="deskListTitle">Capacity:</span>
            <span>{{ item.capacity }}</span>
          </v-row>
        </v-col>
        <v-col cols="3" md="3" style="line-height: 90px;">
          <img @click="clickCanlender(item)"
               aria-label="MakeBooking"
               role="button"
               tabindex="0"
               @keyup.enter="clickCanlender(item)"
               style="margin-right: 10px;cursor:pointer;width:25px"
               src="@/assets/images/map/calendar.png"
               alt=""
          >
          <img @click="localtionMap(item)"
               aria-label="location"
               role="button"
               tabindex="0"
               @keyup.enter="localtionMap(item)"
               style="cursor: pointer;width:19px"
               src="@/assets/images/map/location.png"
               alt=""
          >
          <!--<i class="iconfont icon-rili" style="font-weight: bold;margin-right: 10px"></i>-->
          <!--<i class="iconfont icon-zuobiao"></i>-->
        </v-col>
      </v-row>
    </v-row>

  </div>
</template>

<script>
import newImg from "@/views/dashboard/componentsHa/img";
export default {
  name: "deskList",
  props: {
    data: {
      type: Array
    }
  },
  components: {
    newImg
  },
  data() {
    return {}
  },
  methods: {
    clickCanlender(item) {
      console.log('item:',item);
      this.$emit('clickCanlender', item.id || item.resourceId);
    },
    localtionMap(item) {
      // this.$emit("acceptMap", item);
      this.$emit("acceptMap", item.id || item.resourceId);
    },
  },

}
</script>

<style scoped lang="scss">
.lists {
  .one-list {
    padding:20px 5px;
    .one-list-container{
      min-height: 100px;
    }
    &:nth-child(odd) {
      background: #f3f3f3;
    }
  }

  .xtitle {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 10px;
  }

  i {
    //color: $main-blue;
    font-size: 2.2rem;
    cursor: pointer;
  }

  .deskListTitle {
    min-width: 4.7rem;
  }
}
</style>
